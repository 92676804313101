import * as React from 'react'
import type { HeadFC } from 'gatsby'

import Header from '../common/Header'
import Footer from '../common/Footer'
import Social from '../common/Social'

import detail1 from '../images/logo/detail_1.png'
import detail2 from '../images/logo/detail_2.png'
import detail3 from '../images/logo/detail_3.png'
import working from '../images/imgs/working.png'
import launchZone from '../images/logo/launch_zone.png'
import vsv from '../images/logo/vsv.png'
import mistletoe from '../images/logo/mistletoe.png'
import hub from '../images/logo/hub.png'

import { ADVISORS } from '../constants'

const PARTNER = [
  {
    logo: launchZone,
    description: 'Accelerated multiple blockchain startups to success',
  },
  {
    logo: mistletoe,
    description:
      'Tokyo-based VC investing in tech companies working to solve global challenges',
  },
  {
    logo: vsv,
    description:
      '75+ investments scaling up startups to lead dominant industries',
  },
  {
    logo: hub,
    description:
      'Web3-focused fund in partnership with unicorns and tech frontiers',
  },
]

const VALUES = [
  {
    title: 'Tokenomics design',
    description:
      'Tokenomic design isn’t just about making sure the tokens has real utilities. Hectagon’s investment team works with projects to:',
    listContent: [
      'Ensure the rate of token & policy of token distribution are directly conducive to the project growth.',
      'Effectively work with Market Maker to support the token launch appropriately.',
    ],
  },
  {
    title: 'Go to market',
    description:
      'Build community, prepare and execute go-to-market plan is a vital part of any succesful project. Hectagon will provide support in:',
    listContent: [
      'Plan and execute community growth campaigns.',
      'Identify the right KOLs and the right channels to effectively market the project without overreaching and wasting resources.',
    ],
  },
  {
    title: 'Technical support',
    description:
      'Build and deliver product is something that projects will need internal capabilities to do. Hectagon will provide support in the following area:',
    listContent: [
      'Service provider and development support',
      'Technical advisory and audit',
    ],
  },
]

const IndexPage = () => {
  return (
    <>
      <Header />
      <main>
        <section className="w-10/12 m-auto">
          <div className="flex justify-between items-center py-24">
            <div>
              <h1 className="text-6xl text-linear-title font-semibold">
                Raise capital{' '}
              </h1>
              <p className="text-3xl">
                from a VC platform will stick around for the{' '}
                <span className="text-linear font-semibold">long run</span> -
                backed by star advisors!
              </p>

              <div className="flex items-center">
                <button className="py-3 w-60 order-1 rounded-lg bg-primary text-white text-base font-semibold mt-4 mr-2">
                  Raise capital
                </button>

                <button className="py-3 px-20 border-primary order-1 rounded-lg border-2 text-primary border-inherit text-base font-semibold mt-4">
                  Refer a project
                </button>
              </div>
            </div>

            <div className="background_linear p-8 rounded-2xl ml-16">
              <div className="grid gap-4 grid-cols-3">
                {ADVISORS.map((item) => (
                  <div className="h-60">
                    <img
                      src={item.image}
                      alt=""
                      className="h-full border-2 rounded-lg"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="background_linear pt-20 text-white mt-10">
          <div className="w-10/12 m-auto">
            <h3 className="text-3xl font-semibold">What is Hectagon?</h3>
            <p className="w-6/12 mt-4">
              Hectagon is a DAO-governed Venture Capital funding platform for
              Web3 projects designed and operated around the thesis of:
            </p>

            <div className="grid gap-4 grid-cols-3 mt-12 pb-32">
              <div className="flex items-center border-r pr-10">
                <img src={detail1} alt="" />

                <p className="ml-5">
                  Thorough selection from commited team, useful solutions and
                  products
                </p>
              </div>

              <div className="flex items-center border-r px-10">
                <img src={detail2} alt="" />

                <p className="ml-5">Long-term relationship driven</p>
              </div>

              <div className="flex items-center pl-10">
                <img src={detail3} alt="" />

                <p className="ml-5">
                  Value-added on Tokenomics design and Go To Market execution
                </p>
              </div>
            </div>

            <div className="flex items-center mb-16">
              <img src={working} alt="" className="w-2/5 mr-24" />

              <div>
                <h3 className="text-3xl font-semibold">
                  Simple and easy-to-use workflow
                </h3>

                <p className="pt-10 pb-8">
                  Raising fund from Hectagon platform isn’t the same as
                  crowfunding. Projects don’t need to manage a crowdfund
                  campaign to raise seed capital.
                </p>

                <p>
                  The process is designed as simple as submit & publish your
                  project information on our investment portal. Our investment
                  team will start the procedure of screening, interview and due
                  diligence.
                </p>
              </div>
            </div>

            <div className="background_linear px-12 py-9 flex items-center justify-between rounded-2xl translate-y-1/2">
              <div>
                <h3 className="text-3xl mb-7">
                  Start investing now with our 40K members, and growing
                  community!
                </h3>

                <div className="w-4/12 h-6/12">
                  <Social />
                </div>
              </div>

              <div className="flex items-center ml-32">
                <button className="py-3 w-60 order-1 rounded-lg bg-primary text-white text-base font-semibold mt-4 mr-2">
                  Raise capital
                </button>

                <button className="py-3 px-20 order-1 rounded-lg bg-white text-primary border-inherit text-base font-semibold whitespace-nowrap mt-4">
                  Refer a project
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="w-10/12 m-auto mt-64">
          <h3 className="text-3xl text-center font-semibold mb-14">
            Hectagon Partners
          </h3>

          <div className="flex grid gap-16 grid-cols-4">
            {PARTNER.map((item) => (
              <div>
                <div className="flex justify-center py-2">
                  <img src={item.logo} alt="" />
                </div>

                <p className="text-center">{item.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="w-10/12 m-auto mt-36 mb-40">
          <h3 className="text-3xl font-semibold mb-4">
            How does Hectagon{' '}
            <span className="text-linear-title">add value?</span>
          </h3>

          <div className="flex grid gap-8 grid-cols-3">
            {VALUES.map((item, index) => (
              <>
                {index ? (
                  <div className="p-6 card">
                    <h2 className="text-2xl font-semibold mb-3 text-linear-title">
                      {item.title}
                    </h2>

                    <p>
                      Build community, prepare and execute go-to-market plan is
                      a vital part of any succesful project. Hectagon will
                      provide support in:
                    </p>

                    <ul className="list-disc ml-6">
                      {item.listContent.map((listItem) => (
                        <li className="text-left">{listItem}</li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div className="p-6 background_linear rounded-2xl text-white">
                    <h2 className="text-2xl font-semibold mb-3">
                      {item.title}
                    </h2>

                    <p>
                      Build community, prepare and execute go-to-market plan is
                      a vital part of any succesful project. Hectagon will
                      provide support in:
                    </p>

                    <ul className="list-disc ml-6">
                      {item.listContent.map((listItem) => (
                        <li className="text-left">{listItem}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            ))}
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Project Page</title>
